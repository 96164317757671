import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { gray, black, blue } from "@vschool/lotus"
import Link from "../components/shared/QueryLink"

const Container = styled.section`
  background-color: ${gray.light};
  padding-top: 64px;
  padding-bottom: 64px;
  display: block;
  min-height: 60vh;

  @media (min-width: 840px) {
    padding-bottom: 120px;
    padding-top: 120px;
  }
`

const Title = styled.h6`
  color: ${black};
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 24px;
  margin-bottom: 16px;
  text-align: center;
  font-weight: 800;

  @media (max-width: 400px) {
    font-size: 12px;
  }

  @media (min-width: 1200px) {
    font-size: 16px;
  }
`

const H1 = styled.h1`
  font-size: 36px;
  font-weight: 900;
  line-height: 40px;
  text-align: center;
  color: ${black};
  margin-bottom: 16px;

  @media (min-width: 600px) {
    font-size: 40px;
  }

  @media (min-width: 840px) {
    font-size: 48px;
    line-height: 48px;
  }

  @media (min-width: 1200px) {
    font-size: 56px;
    padding-left: 160px;
    padding-right: 160px;
    line-height: 56px;
  }
`

const P = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  margin-bottom: 24px;
  width: 460px;

  @media (min-width: 840px) {
    font-size: 18px;
    line-height: 26px;
    width: 600px;
  }

  @media (min-width: 1200px) {
    font-size: 20px;
    line-height: 28px;
    width: 700px;
  }
`

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
`

const LinksContainer = styled.div`
  display: flex;
  width: 377px;
  justify-content: space-between;

  @media (max-width: 425px) {
    flex-direction: column;
    align-items: center;
  }
`

const SLink = styled(Link)`
  text-decoration: none;
  color: ${blue.base};
  font-family: "aktiv-grotesk";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  @media (max-width: 425px) {
    margin: 8px 0;
  }
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <Title>404 ERROR - PAGE NOT FOUND</Title>
      <FlexContainer>
        <H1>Sorry About That</H1>
      </FlexContainer>
      <FlexContainer>
        <P>
          We weren't able to find the page that you were looking for. Go ahead
          and hit the ol' back button or feel free to click on one of these
          handy links below.
        </P>
      </FlexContainer>
      <FlexContainer>
        <LinksContainer>
          <SLink to="/">Home</SLink>
          <SLink to={"/my-base-scholarship"}>My Base Scholarship</SLink>
        </LinksContainer>
      </FlexContainer>
    </Container>
  </Layout>
)

export default NotFoundPage
